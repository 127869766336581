import { WppModal, WppSpinner, WppSelect, WppListItem } from '@wppopen/components-library-react'
import { useState, useEffect, useCallback, useMemo } from 'react'
import { NavigateFunction, useSearchParams } from 'react-router-dom'

import { Paginator } from 'api/common/types'
import EmptyState from 'components/EmptyState'
import { useApiSearch } from 'hooks/useApiSearch'
import { useApiSortFilters, ApiSortTypes } from 'hooks/useApiSortFilters'
import { useTranslation } from 'hooks/useTranslation'
import { SettingsTabs } from 'pages/settings/Settings'
import { FetchUseCasesFiltersParams, UseCase, UseCasesMeta, UseCasesFilterKeys } from 'types/use-cases/useCase'
import { NO_RESULTS_TITLE, NO_RESULTS_DESCRIPTION } from 'utils/translations'

import { UseCaseDeleteModal } from '../UseCaseDeleteModal'
import { UseCasesAgGrid } from '../useCasesAgGrid'
import { UseCasesCards } from '../useCasesCards/UseCasesCards'
import { sortType } from '../useCasesFilterGroup/types'
import { UseCasesFilterGroup } from '../useCasesFilterGroup/UseCasesFilterGroup'

export interface Props {
  isFetching: boolean
  navigate: NavigateFunction
  useCases: UseCase[]
  paginator: Paginator
  meta: UseCasesMeta
  customError?: string
}

type FilterKeys = { [K in (typeof UseCasesFilterKeys)[number]]: string[] }

const sortInitParams: ApiSortTypes<sortType> = {
  createdAt: 'DESC',
}

const confidentialFilterValues = { yes: 'Confidential', no: 'Not confidential', all: 'All' }

export const UseCasesDashboardView = ({ navigate, useCases, isFetching, paginator, meta, customError }: Props) => {
  const getTranslation = useTranslation()
  const [searchUrlParamName, setSearchTerm] = useApiSearch('title', isFetching)
  const [{ sortingState }, handleSortFilters] = useApiSortFilters<typeof sortInitParams, sortType>(sortInitParams)
  const [searchParams, setSearchParams] = useSearchParams()
  const handleEditClick = (id: string) => {
    navigate(`/rfi-helper-tool/settings/use-cases/${id}`)
  }
  const [toggleView, setToggleView] = useState<'grid' | 'list'>((searchParams.get('mode') as 'grid' | 'list') || 'grid')
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const [id, setId] = useState('')
  const [name, setname] = useState('')

  const handleDeleteClick = async (id: string, name?: string) => {
    setId(id)
    if (name) {
      setname(name)
    }
    setIsDeleteModalOpen(true)
  }

  const handleToggle = (value: 'grid' | 'list') => {
    setSearchParams(prev => {
      prev.set('view', SettingsTabs.USE_CASES)
      prev.set('mode', value)
      return prev
    })
    setToggleView(value)
  }

  const deriveStateFromParams = useCallback(
    (reset = false) => {
      if (reset) {
        return UseCasesFilterKeys.reduce((acc, key) => {
          acc[key] = []
          return acc
        }, {} as FilterKeys)
      }
      return UseCasesFilterKeys.reduce((acc, key) => {
        if (key !== 'filter[isConfidential]') {
          acc[key] = searchParams.get(key) ? searchParams.get(key)!.split(',') : []
        } else {
          if (searchParams.get(key)) {
            searchParams.get(key) === 'true' ? (acc[key] = ['yes']) : (acc[key] = ['no'])
          } else {
            acc[key] = searchParams.get('all') ? ['all'] : []
          }
        }
        return acc
      }, {} as FilterKeys)
    },
    [searchParams],
  )

  const [searchValue, setSearchValue] = useState<string>(searchParams.get(searchUrlParamName) ?? '')

  const [filterValues, setFilterValues] = useState(deriveStateFromParams())

  const numActiveFilters = useMemo(() => {
    let sum = 0
    UseCasesFilterKeys.forEach(key => {
      if (filterValues[key].length > 0) {
        sum++
      }
    })
    return sum
  }, [filterValues])

  const numActiveFiltersFromParams = useMemo(() => {
    // this is to preserve empty state when we click reset filters without apply
    const filterValues = deriveStateFromParams()
    let sum = 0
    UseCasesFilterKeys.forEach(key => {
      if (filterValues[key].length > 0) {
        sum++
      }
    })
    return sum
  }, [deriveStateFromParams])

  const [isDefaultState, setIsDefaultState] = useState<boolean>(numActiveFilters === 0)

  useEffect(() => {
    setIsDefaultState(numActiveFilters === 0)
  }, [numActiveFilters])

  useEffect(() => {
    if (!searchParams.get('view')) {
      setSearchParams(prev => {
        prev.set('view', SettingsTabs.USE_CASES)
        prev.set('mode', toggleView)
        return prev
      })
    }
    if (!searchParams.get('page')) {
      setSearchParams(prev => {
        prev.set('page', '1')
        return prev
      })
    }
    setFilterValues(deriveStateFromParams())
  }, [searchParams, setSearchParams, toggleView, deriveStateFromParams])

  const deriveActiveFiltersFromState = useCallback((): FetchUseCasesFiltersParams => {
    return UseCasesFilterKeys.reduce((acc, key) => {
      if (key !== 'filter[isConfidential]') {
        acc[key] = filterValues[key].length > 0 ? filterValues[key].join(',') : null
      } else {
        if (filterValues[key].length > 0 && filterValues[key][0] !== 'all') {
          filterValues[key][0] === 'yes' ? (acc[key] = 'true') : (acc[key] = 'false')
        } else {
          acc[key] = null
        }
      }
      return acc
    }, {} as FetchUseCasesFiltersParams)
  }, [filterValues])

  const [activeFilters, setActiveFilters] = useState<FetchUseCasesFiltersParams>(deriveActiveFiltersFromState())

  const deriveStateFromActiveFilters = useCallback(() => {
    return UseCasesFilterKeys.reduce((acc, key) => {
      if (key !== 'filter[isConfidential]') {
        acc[key] = activeFilters[key] ? activeFilters[key]!.split(',') : []
      } else {
        if (activeFilters[key]) {
          activeFilters[key] === 'true' ? (acc[key] = ['yes']) : (acc[key] = ['no'])
        } else {
          acc[key] = []
        }
      }
      return acc
    }, {} as FilterKeys)
  }, [activeFilters])

  const handleResetFilters = () => {
    handleCancelFilters(true)
    setTimeout(() => {
      setIsDefaultState(true)
    }, 10)
  }

  const handleApplyFilters = () => {
    applyAllFilters(deriveActiveFiltersFromState())
  }

  const applyAllFilters = (activeFilters: FetchUseCasesFiltersParams) => {
    setTimeout(() => {
      setSearchParams(prev => {
        prev.set('page', '1')
        return prev
      })
      setActiveFilters(activeFilters)
      if (filterValues['filter[isConfidential]'].length && filterValues['filter[isConfidential]'][0] === 'all') {
        setSearchParams(prev => {
          prev.set('all', 'true')
          return prev
        })
      } else {
        setSearchParams(prev => {
          prev.delete('all')
          return prev
        })
      }
    }, 0)
  }

  useEffect(() => {
    for (const [key, value] of Object.entries(activeFilters)) {
      setSearchParams(prev => {
        if (value) {
          prev.set(key, value)
        } else {
          prev.delete(key)
        }
        return prev
      })
    }
  }, [activeFilters, setSearchParams])

  const handleCancelFilters = (reset = false) => {
    if (reset) {
      setFilterValues(deriveStateFromParams(true))
    } else {
      setFilterValues(deriveStateFromActiveFilters())
    }
  }

  const handleSearchChange = useCallback(
    (value: string) => {
      setSearchValue(value)
      setSearchTerm(value)
    },
    [setSearchTerm],
  )

  const { clients, markets, agencies, createdBys, pitches, subCategories } = meta

  const isEmpty = useCases.length === 0 && !isFetching && (numActiveFiltersFromParams > 0 || searchValue.length > 0)

  return (
    <div className="mt-7">
      <UseCasesFilterGroup
        disabled={isFetching || customError !== undefined}
        handleToggle={handleToggle}
        handleApplyFilters={handleApplyFilters}
        handleResetFilters={handleResetFilters}
        handleCancelFilters={handleCancelFilters}
        handleSearchChange={handleSearchChange}
        filterCount={numActiveFilters}
        resetFiltersVisible={!isDefaultState}
        handleSortFilter={handleSortFilters}
        sortingState={sortingState}
        searchValue={searchValue}
        toggleView={toggleView}
        filters={
          <>
            <WppSelect
              aria-label="Confidentiality"
              title="Confidentiality"
              placeholder="Select confidentiality"
              type="single"
              labelConfig={{
                text: 'Confidentiality',
              }}
              value={
                filterValues['filter[isConfidential]'].length === 0
                  ? ''
                  : confidentialFilterValues[
                      filterValues['filter[isConfidential]'][0] as keyof typeof confidentialFilterValues
                    ]
              }
              onWppChange={e => {
                setFilterValues(prev => {
                  return {
                    ...prev,
                    'filter[isConfidential]': [
                      Object.keys(confidentialFilterValues).find(
                        key =>
                          confidentialFilterValues[key as keyof typeof confidentialFilterValues] === e.detail.value,
                      ) as string,
                    ],
                  }
                })
              }}
            >
              {Object.values(confidentialFilterValues)?.map(item => (
                <WppListItem key={`${item}-agencies`} value={item}>
                  <p slot="label">{item}</p>
                </WppListItem>
              ))}
            </WppSelect>

            <WppSelect
              aria-label="Agencies"
              title="Agencies"
              placeholder="Select Agencies"
              type="multiple"
              labelConfig={{
                text: 'Agencies',
              }}
              withSearch
              withFolder
              value={filterValues['filter[agencyIds]']}
              onWppChange={e => {
                setFilterValues(prev => {
                  return { ...prev, 'filter[agencyIds]': e.detail.value }
                })
              }}
            >
              {agencies?.map(item => (
                <WppListItem key={`${item.id}-agencies`} value={item.id}>
                  <p slot="label">{item.name}</p>
                </WppListItem>
              ))}
            </WppSelect>
            <WppSelect
              aria-label="Clients"
              title="Clients"
              placeholder="Select clients"
              type="multiple"
              labelConfig={{
                text: 'Clients',
              }}
              withFolder
              withSearch
              value={filterValues['filter[client]']}
              onWppChange={e => {
                setFilterValues(prev => {
                  return { ...prev, 'filter[client]': e.detail.value }
                })
              }}
            >
              {clients?.map(item => (
                <WppListItem key={`${item.id}-clients`} value={item.id}>
                  <p slot="label">{item.name}</p>
                </WppListItem>
              ))}
            </WppSelect>
            <WppSelect
              aria-label="Markets"
              title="Markets"
              placeholder="Select markets"
              type="multiple"
              labelConfig={{
                text: 'Markets',
              }}
              withSearch
              withFolder
              onWppChange={e => {
                setFilterValues(prev => {
                  return { ...prev, 'filter[markets]': e.detail.value }
                })
              }}
              value={filterValues['filter[markets]']}
            >
              {markets?.map(
                item =>
                  item.name.length > 0 && (
                    <WppListItem key={`${item.name}-markets`} value={item.name}>
                      <p slot="label">{item.name}</p>
                    </WppListItem>
                  ),
              )}
            </WppSelect>

            <WppSelect
              aria-label="Pitch types"
              title="Pitch Type"
              placeholder="Select pitch type"
              type="multiple"
              labelConfig={{
                text: 'Pitch Type',
              }}
              withSearch
              value={filterValues['filter[pitchTypes]']}
              withFolder
              onWppChange={e => {
                setFilterValues(prev => {
                  return { ...prev, 'filter[pitchTypes]': e.detail.value }
                })
              }}
            >
              {pitches?.map(item => (
                <WppListItem key={`${item.id}-pitches`} value={item.id}>
                  <p slot="label">{item.name}</p>
                </WppListItem>
              ))}
            </WppSelect>
            <WppSelect
              aria-label="Subcategories"
              title="Subcategories"
              placeholder="Select subcategory"
              type="multiple"
              labelConfig={{
                text: 'Subcategories',
              }}
              withSearch
              value={filterValues['filter[subCategory]']}
              withFolder
              onWppChange={e => {
                setFilterValues(prev => {
                  return { ...prev, 'filter[subCategory]': e.detail.value }
                })
              }}
            >
              {subCategories?.map(
                item =>
                  item.name.length > 0 && (
                    <WppListItem key={`${item.name}-sub`} value={item.name}>
                      <p slot="label">{item.name}</p>
                    </WppListItem>
                  ),
              )}
            </WppSelect>
            <WppSelect
              aria-label="Added by"
              title="Added by"
              placeholder="Select users"
              type="multiple"
              labelConfig={{
                text: 'Added by',
              }}
              withSearch
              withFolder
              value={filterValues['filter[createdBy]']}
              onWppChange={e => {
                setFilterValues(prev => {
                  return { ...prev, 'filter[createdBy]': e.detail.value }
                })
              }}
              dropdownConfig={{ placement: 'top' }}
            >
              {createdBys?.map(item => (
                <WppListItem key={item.email} value={item.email}>
                  <p slot="label">{item.name}</p>
                </WppListItem>
              ))}
            </WppSelect>
          </>
        }
      />

      {isFetching && (
        <div className="flex flex-row items-start justify-center h-52">
          <WppSpinner size="l" />
        </div>
      )}

      {customError !== undefined && <EmptyState isError title="Error" description={customError} />}
      {isEmpty && customError === undefined && (
        <EmptyState title={getTranslation(NO_RESULTS_TITLE)} description={getTranslation(NO_RESULTS_DESCRIPTION)} />
      )}
      {toggleView === 'grid' && !isEmpty && !isFetching && (
        <UseCasesCards
          paginator={paginator}
          useCases={useCases}
          handleEditClick={handleEditClick}
          handleDeleteClick={handleDeleteClick}
        />
      )}

      {toggleView === 'list' && !isEmpty && !isFetching && (
        <UseCasesAgGrid
          paginator={paginator!}
          items={useCases}
          handleEditClick={handleEditClick}
          handleDeleteClick={handleDeleteClick}
          handleSortFilter={handleSortFilters}
          sortingState={sortingState}
        />
      )}

      <WppModal open={isDeleteModalOpen} onWppModalClose={() => setIsDeleteModalOpen(false)} size="s">
        <UseCaseDeleteModal
          useCaseId={id}
          name={name}
          handleModalClose={() => {
            setIsDeleteModalOpen(false)
          }}
          handleDeleteSuccess={() => {
            setIsDeleteModalOpen(false)
          }}
        />
      </WppModal>
    </div>
  )
}
