// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.IZDAe:not([active=false])::part(extended-item){color:var(--wpp-brand-color) !important;background-color:var(--wpp-primary-color-100) !important}.IZDAe:not([active=false])::part(label){color:var(--wpp-brand-color) !important}.IZDAe:not([active=false])::part(icon-end-wrapper){--ns-item-icons-color: var(--wpp-brand-color) !important;--wpp-prop-icon-color: var(--wpp-brand-color) !important}.uuqEr{text-indent:1rem !important}.uuqEr:not([active=false])::part(link-item){color:var(--wpp-brand-color) !important;background-color:var(--wpp-primary-color-100) !important}.uuqEr:not([active=false])::part(label){color:var(--wpp-brand-color) !important}`, "",{"version":3,"sources":["webpack://./src/pages/agency-edit-detail/AgencyEditKnowledgeBase.module.scss"],"names":[],"mappings":"AACE,gDACE,uCAAA,CACA,wDAAA,CAEF,wCACI,uCAAA,CAEJ,mDACE,wDAAA,CACA,wDAAA,CAIJ,OACE,2BAAA,CAGE,4CACE,uCAAA,CACA,wDAAA,CAEF,wCACI,uCAAA","sourcesContent":[".sideMenuItem:not([active=\"false\"]) {\n  &::part(extended-item) {\n    color: var(--wpp-brand-color) !important;\n    background-color: var(--wpp-primary-color-100) !important;\n  }\n  &::part(label) { \n      color: var(--wpp-brand-color) !important;\n  }\n  &::part(icon-end-wrapper) { \n    --ns-item-icons-color: var(--wpp-brand-color) !important;\n    --wpp-prop-icon-color: var(--wpp-brand-color) !important;\n  }\n}\n\n.sideMenuIndentedItem {\n  text-indent: 1rem !important;\n\n  &:not([active=\"false\"]) {\n    &::part(link-item) {\n      color: var(--wpp-brand-color) !important;\n      background-color: var(--wpp-primary-color-100) !important;\n    }\n    &::part(label) { \n        color: var(--wpp-brand-color) !important;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sideMenuItem": `IZDAe`,
	"sideMenuIndentedItem": `uuqEr`
};
export default ___CSS_LOADER_EXPORT___;
