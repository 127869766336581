import {
  WppTextareaInput,
  WppButton,
  WppInput,
  WppIconTrash,
  WppTypography,
  WppModal,
} from '@wppopen/components-library-react'
import { AxiosError } from 'axios'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { useUpdateAgency } from 'api/mutations/agencies/useUpdateAgency'
import { useToast } from 'hooks/useToast'
import { Agency } from 'types/agencies/agencies'

import style from './addagency.module.scss'
import { AgencyDeleteModal } from './AgencyDeleteModal'

export interface EditAgencyProps {
  agency: Agency
  handleCancel?: () => void
  handleSave?: () => void
}

const MAX_LENGTH_DESCRIPTION = 2000

export const EditAgency = ({ agency, handleCancel, handleSave }: EditAgencyProps) => {
  const toast = useToast()
  const [descriptionValue, setDescriptionValue] = useState<string>(agency.description)
  const [nameValue, setNameValue] = useState<string>(agency.name)
  const textAreaRef = useRef<HTMLWppTextareaInputElement>(null)
  const [isSaving, setIsSaving] = useState(false)
  const [agencyExists, seAgencyExists] = useState(false)
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false)
  const [hasCancelled, setHasCancelled] = useState(false)
  const canSave = !!nameValue && descriptionValue.length < MAX_LENGTH_DESCRIPTION
  const { mutateAsync: updateAgency } = useUpdateAgency()
  const navigate = useNavigate()

  const handleClearAll = () => {
    setNameValue('')
    setDescriptionValue('')
    seAgencyExists(false)
  }

  const onHandleCancel = useCallback(() => {
    handleClearAll()
    handleCancel?.()
  }, [handleCancel])

  const handleDeleteButtonClick = () => {
    setIsDeleteModalOpen(true)
  }

  const onHandleSave = async () => {
    setIsSaving(true)

    if (!nameValue) {
      return toast.showToast({
        message: 'Please enter a name for the agency',
        type: 'error',
      })
    }

    try {
      await updateAgency({ agencyId: agency.id, params: { name: nameValue, description: descriptionValue } })

      handleSave?.()
    } catch (e) {
      const error = e as AxiosError
      if (error.status === 409) {
        toast.showToast({
          message: 'Agency name already exists. Please use a different one.',
          type: 'error',
        })
        seAgencyExists(true)
        return
      }

      toast.showToast({
        message: 'Something went wrong while saving the agency. Please try again.',
        type: 'error',
      })
    } finally {
      setIsSaving(false)
    }
  }

  useEffect(() => {
    if (hasCancelled) {
      onHandleCancel()
    }
  }, [hasCancelled, onHandleCancel])

  return (
    <>
      <div className="flex flex-col gap-6">
        <div className="flex items-end gap-4">
          <WppInput
            name={nameValue}
            placeholder="Add an agency name"
            required
            value={nameValue}
            onWppChange={e => {
              setNameValue(e?.detail?.value || '')
              seAgencyExists(false)
            }}
            messageType={agencyExists ? 'error' : undefined}
            message={agencyExists ? 'Name already exists. Use a different one.' : undefined}
            className="flex-1"
          />

          <div key="delete-btn">
            <WppButton key="delete-btn" variant="destructive-secondary" onClick={handleDeleteButtonClick}>
              <div className="flex items-center gap-2">
                <WppIconTrash color="" size="s" />
                <WppTypography type="s-strong" className={style.dangerColor}>
                  Delete Agency
                </WppTypography>
              </div>
            </WppButton>
          </div>
        </div>
        <WppTextareaInput
          ref={textAreaRef}
          labelConfig={{
            text: 'Description',
          }}
          name="description"
          placeholder="Describe agency"
          onWppChange={e => setDescriptionValue(e?.detail?.value || '')}
          value={descriptionValue}
          charactersLimit={MAX_LENGTH_DESCRIPTION}
          maxMessageLength={MAX_LENGTH_DESCRIPTION}
          warningThreshold={MAX_LENGTH_DESCRIPTION - 100}
          content={descriptionValue}
          className={style.textArea}
        />
      </div>

      <div className="fixed flex justify-end gap-4 py-6 px-8 bg-white z-80 left-0 bottom-0 w-full border-t border-solid border-grey50">
        <WppButton onClick={() => setIsConfirmModalOpen(true)} variant="secondary" disabled={isSaving}>
          Cancel
        </WppButton>
        <WppButton
          onClick={() => {
            onHandleSave()
          }}
          disabled={!canSave || isSaving}
        >
          Save
        </WppButton>
      </div>

      <WppModal open={isDeleteModalOpen} onWppModalCloseComplete={() => setIsDeleteModalOpen(false)} size="s">
        <AgencyDeleteModal
          agencyId={agency?.id || ''}
          name={agency?.name || ''}
          handleModalClose={isAgencyDeleted => {
            setIsDeleteModalOpen(false)
            if (isAgencyDeleted) {
              navigate(-1)
            }
          }}
        />
      </WppModal>

      <WppModal size="s" open={isConfirmModalOpen}>
        <h3 slot="header">Are you sure you want to cancel?</h3>
        <p slot="body">You have unsaved changes. Are you sure you want to continue?</p>

        <div slot="actions" className="flex flex-row justify-end gap-4">
          <WppButton variant="secondary" onClick={() => setIsConfirmModalOpen(false)} size="s">
            Go back
          </WppButton>
          <WppButton
            size="s"
            variant="destructive"
            onClick={() => {
              setIsConfirmModalOpen(false)
              setHasCancelled(true)
            }}
          >
            Confirm
          </WppButton>
        </div>
      </WppModal>
    </>
  )
}
