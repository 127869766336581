import { WppIconFile, WppSpinner, WppTag, WppTypography } from '@wppopen/components-library-react'
import { useMemo } from 'react'
import Markdown from 'react-markdown'

import { useGetAllAgencies } from 'api/queries/agencies/useGetAgencies'
import { useClients } from 'api/queries/clients/useClients'
import { useUseCase } from 'api/queries/use-cases/useUseCase'
import { useFileViewOrDownload } from 'hooks/useFileViewOrDownload'
import { getFileNameFromUrl } from 'utils/projectUtils'

import styles from './useCasesDetails.module.scss'
import UseCaseSummaryError from './UseCaseSummaryError'

export const UseCasesDetails = ({ useCaseId }: { useCaseId: string }) => {
  const { data: useCase, isFetching } = useUseCase({ params: { id: useCaseId || '' }, refetchOnWindowFocus: true })
  const { data: clients = [] } = useClients()
  const { data: agencies = [] } = useGetAllAgencies()
  const [isFetchingFile, viewOrDownloadFile] = useFileViewOrDownload()
  const {
    description,
    fileName,
    fileSize,
    summary,
    agencyIds,
    markets,
    client,
    pitchTypes,
    statusCode,
    subCategory,
    isConfidential,
    fileUrl,
    fileKey,
  } = useCase

  const agencyNames = useMemo(() => {
    return agencies.filter(agency => agencyIds?.includes(agency.id)).map(agency => agency.name)
  }, [agencies, agencyIds])

  if (isFetching) return <WppSpinner size="s" />
  const size = fileSize ? Number(fileSize / (1000 * 1000)).toFixed(2) + 'MB' : ''
  const file = fileName ? fileName : getFileNameFromUrl(fileUrl) || ''
  return (
    <>
      {description && (
        <div>
          <WppTypography type="s-body">{description}</WppTypography>
        </div>
      )}
      <div>
        {isConfidential ? (
          <WppTag label="Confidential file" variant="warning" />
        ) : (
          <WppTag label="Non-Confidential file" variant="warning" />
        )}
      </div>

      {[
        { label: 'Agencies', value: agencyNames.join(', ') },
        { label: 'Client', value: clients.find(el => el.id === client)?.name || client },
        { label: 'Markets', value: markets.join(', ') },
        { label: 'Pitch Types', value: pitchTypes.join(', ') },
        { label: 'Subcategory', value: subCategory },
      ].map((item, idx) => (
        <div className="flex gap-2" key={`${item.label}-${idx}`}>
          <WppTypography className="text-grey" type="s-strong">
            {item.label}:
          </WppTypography>
          <WppTypography type="s-body">{item.value}</WppTypography>
        </div>
      ))}

      <WppTypography type="xl-heading">Uploaded File</WppTypography>
      <div
        style={{ pointerEvents: isFetchingFile ? 'none' : 'auto' }}
        className="flex gap-2 bg-[#F4F5F7] rounded-6 p-3 cursor-pointer"
        // @ts-ignore
        onClick={e => {
          e.preventDefault()
          e.stopPropagation()
          viewOrDownloadFile({ fileObjects: [{ key: fileKey, name: fileName }] })
        }}
      >
        <WppIconFile />
        <WppTypography type="s-body" className="underline text-[#343A3F]">
          {/*replace with file name from api after it's updated*/}
          {file}
          {'  '}
        </WppTypography>
        <WppTypography type="s-body" className="text-[#697077]">
          {/* file size should be from the api */}
          {size}
        </WppTypography>
      </div>

      <div className={styles.markDownWrapper}>
        <WppTypography className="mb-2" color="#121619" type="l-strong">
          LLM Generated Summary:
        </WppTypography>
        {statusCode === 0 && (
          <WppTypography type="s-body">
            <Markdown>{summary}</Markdown>
          </WppTypography>
        )}
        {statusCode !== 0 && <UseCaseSummaryError />}
      </div>
    </>
  )
}
