import { FileItemType } from '@wppopen/components-library/dist/types/components'
import {
  WppButton,
  WppModal,
  WppSideModal,
  WppStep,
  WppStepper,
  WppTypography,
  WppDivider,
  WppSelect,
  WppListItem,
  WppInlineMessage,
} from '@wppopen/components-library-react'
import { AxiosResponse } from 'axios'
import { useEffect, useState, useCallback } from 'react'

import { useAddAgencyFile } from 'api/mutations/agencies/useAddAgencyFile'
import { useFileUploadUrls } from 'api/queries/files/useFileUploadUrls'
import { queryClient } from 'app/Root'
import { WppControlledUpload, FileUploadChangeProps } from 'components/wpp-controlled-upload/WppControlledUpload'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { useToast } from 'hooks/useToast'
import { KnowledgeBaseMergeSelection } from 'pages/agency-knowledge-base/KnowledgeBaseMergeSelection'

import { Agency, AgencyFileAddedResponse } from '@/types/agencies/agencies'

export const AgencyAddFileModal = ({
  agency,
  isOpen,
  onClose,
  onSave,
}: {
  agency: Agency
  isOpen: boolean
  onClose: () => void
  onSave: () => void
}) => {
  const toast = useToast()
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const [wppFiles, setWppFiles] = useState<FileItemType[]>([])
  const [contentStrategy, setContentStrategy] = useState<'merge' | 'replace' | undefined>(undefined)
  const [fileHasError, setFileHasError] = useState(false)
  const [fileStatusTaskId, setFileStatusTaskId] = useState<string | null>(null)
  const [step, setStep] = useState(1)
  const [{ data: fileUploadUrls }, generateFileUploadUrls] = useFileUploadUrls()
  const { mutateAsync: addAgencyFile } = useAddAgencyFile({
    onError: error => {
      toast.showToast({
        message: error.message,
        type: 'error',
      })
    },
  })

  const handleClose = () => {
    if (step === 2 || wppFiles.length > 0) {
      setIsConfirmModalOpen(true)
    } else {
      onClose()
    }
  }

  const onFileUploadChange = useCallback(({ files, hasError }: FileUploadChangeProps) => {
    setWppFiles(files)
    setFileHasError(hasError)
  }, [])

  const handleReset = () => {
    setWppFiles([])
    setStep(1)
    setFileStatusTaskId(null)
    setContentStrategy(undefined)
    setFileHasError(false)
    queryClient.removeQueries({ queryKey: [ApiQueryKeys.FILE_UPLOAD_URLS] })
  }

  const handlePreviewImport = () => {
    setSubmitting(true)
    generateFileUploadUrls({ file_names: wppFiles.map(file => file.name), file_type: 'agency_file' })
  }

  useEffect(() => {
    if (fileUploadUrls && fileUploadUrls.length) {
      uploadFiles()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileUploadUrls])

  useEffect(() => {
    // Make sure to reset the state when the modal is rendered
    // because fileUploadUrls uses react query, the state is global
    // and thus not cleared whenever it is unmounted.
    handleReset()
  }, [])

  const uploadFiles = async () => {
    if (fileUploadUrls && fileUploadUrls.length && wppFiles.length) {
      for (const fileUrl of fileUploadUrls) {
        try {
          const formDataAPI = new FormData()
          const files = wppFiles.map(file => new Blob([file as File], { type: 'application/pdf' }))
          const file = files[0]
          formDataAPI.append('file', file, fileUrl.name)
          const fileResponse = await fetch(fileUrl.signed_url, {
            headers: {
              'Content-Type': 'application/pdf',
              'x-goog-content-length-range': '1,1000000000',
            },
            body: formDataAPI,
            method: 'put',
          })
          if (!fileResponse.ok) {
            throw new Error('File upload failed')
          }
        } catch (e) {
          console.error('Error uploading file', e)
          toast.showToast({
            message: 'Your file upload was not successful',
            type: 'error',
          })
          onClose()
          return
        }
      }

      let fileUploadRes: AxiosResponse<AgencyFileAddedResponse> | null = null
      fileUploadRes = await addAgencyFile({
        params: {
          file_key: fileUploadUrls[0].key,
        },
        agencyId: agency.id,
      })

      setSubmitting(false)
      setFileStatusTaskId(fileUploadRes.data.id)
      setStep(2)
    }
  }

  return (
    <>
      <WppSideModal
        open={isOpen}
        onWppSideModalClose={handleClose}
        size={step === 2 ? '2xl' : 'm'}
        onWppSideModalCloseComplete={() => {
          handleReset()
        }}
      >
        <h3 slot="header">Upload File</h3>
        <div slot="body">
          <div className="flex flex-col -mx-8">
            <WppDivider />

            <div className="w-full pt-6">
              <WppStepper activeStep={step} orientation="horizontal">
                <WppStep active>
                  <p slot="label">Upload File</p>
                </WppStep>
                <WppStep>
                  <p slot="label">Select Content</p>
                </WppStep>
              </WppStepper>
            </div>
            {step === 1 && (
              <>
                <div
                  className="flex flex-col gap-6 px-8 overflow-y-auto py-6"
                  style={{ minHeight: 'calc(100vh - 238px)', height: 'calc(100vh - 238px)' }}
                >
                  <div className="flex flex-col gap-2">
                    <WppTypography type="xl-heading">{agency.name}</WppTypography>
                    <WppTypography type="s-body">
                      Upload a new file to fill out the agency knowledge base content. After uploading the file, you can
                      preview what is being imported and make a selection to confirm the import.
                    </WppTypography>
                  </div>
                  {agency.numberOfCategories > 0 && (
                    <div className="flex flex-col gap-2">
                      <WppSelect
                        aria-label="New Content Upload"
                        title="New Content Upload"
                        placeholder="How you want to manage the content?"
                        labelConfig={{
                          text: 'New Content Upload',
                        }}
                        onWppChange={e => {
                          setContentStrategy(e.detail.value)
                        }}
                        value={contentStrategy}
                        required
                      >
                        <WppListItem value="merge">
                          <p slot="label">Merge Content</p>
                        </WppListItem>
                        <WppListItem value="replace">
                          <p slot="label">Replace Content</p>
                        </WppListItem>
                      </WppSelect>

                      <WppTypography type="xs-body">
                        Decide how you want to manage the new uploaded content? Do you want to replace the existing one
                        or merge together.
                      </WppTypography>

                      {contentStrategy && (
                        <div className="flex flex-col gap-6 py-6">
                          {contentStrategy === 'merge' && (
                            <>
                              <WppTypography type="xs-body">
                                <span className="font-medium">Merge:</span> Adds the content of the uploaded file to the
                                existing text.
                              </WppTypography>
                            </>
                          )}
                          {contentStrategy === 'replace' && (
                            <>
                              <WppTypography type="xs-body">
                                <span className="font-medium">Replace:</span> Overwrites the existing text with the
                                content of the uploaded file.
                              </WppTypography>
                              <WppInlineMessage
                                size="s"
                                message="This action will replace all content for identified category."
                                type="warning"
                              />
                            </>
                          )}
                        </div>
                      )}
                    </div>
                  )}
                  <div className="flex flex-col gap-2">
                    <WppTypography type="s-strong">New File Upload</WppTypography>
                    <WppControlledUpload
                      acceptConfig={{ 'application/pdf': ['.pdf'] }}
                      format="arrayBuffer"
                      value={wppFiles}
                      onFileUploadChange={onFileUploadChange}
                    />
                  </div>
                </div>

                <WppDivider />

                <div className="flex justify-end gap-4 px-8 pt-4 pb-6">
                  <WppButton
                    variant="secondary"
                    onClick={() => {
                      handleClose()
                    }}
                    disabled={submitting}
                  >
                    Cancel
                  </WppButton>
                  <WppButton
                    variant="primary"
                    onClick={() => {
                      handlePreviewImport()
                    }}
                    disabled={
                      (agency.numberOfCategories > 0 && !contentStrategy) ||
                      wppFiles.length === 0 ||
                      fileHasError ||
                      submitting
                    }
                  >
                    {submitting ? 'Uploading...' : 'Preview Import'}
                  </WppButton>
                </div>
              </>
            )}
            {step === 2 && fileStatusTaskId && (
              <>
                <KnowledgeBaseMergeSelection
                  agencyId={agency.id}
                  fileName={wppFiles?.[0]?.name}
                  fileStatusTaskId={fileStatusTaskId}
                  contentStrategy={contentStrategy}
                  emptyKnowledgeBase={agency.numberOfCategories === 0}
                  withContentDividerTop={false}
                  paddingContentTop="80px"
                  handleSave={() => {
                    queryClient.resetQueries({ queryKey: [ApiQueryKeys.FILE_UPLOAD_URLS] })
                    onSave()
                  }}
                  handleCancel={() => {
                    handleClose()
                  }}
                  handleError={() => {
                    onClose()
                  }}
                />
              </>
            )}
          </div>
        </div>
      </WppSideModal>

      <WppModal size="s" open={isConfirmModalOpen}>
        <h3 slot="header">Are you sure you want to cancel?</h3>
        <p slot="body">You have unsaved changes. Are you sure you want to continue?</p>

        <div slot="actions" className="flex flex-row justify-end gap-4">
          <WppButton variant="secondary" onClick={() => setIsConfirmModalOpen(false)} size="s">
            Go back
          </WppButton>
          <WppButton
            size="s"
            variant="destructive"
            onClick={() => {
              setIsConfirmModalOpen(false)
              onClose()
            }}
          >
            Confirm
          </WppButton>
        </div>
      </WppModal>
    </>
  )
}
