import {
  WppButton,
  WppEmptyNothingFound,
  WppIconPlus,
  WppTypography,
  WppEmptyError,
} from '@wppopen/components-library-react'

import { IEmptyState } from './types'

export default function EmptyState({ title, description, action, isError }: IEmptyState) {
  return (
    <div className="flex flex-col gap-6 items-center justify-center w-full h-[65dvh]">
      {isError ? <WppEmptyError /> : <WppEmptyNothingFound />}
      <div className="flex flex-col gap-2 items-center justify-center">
        <WppTypography type="m-strong">{title}</WppTypography>
        <WppTypography type="m-body">{description}</WppTypography>
      </div>
      {action && (
        <WppButton onClick={action.handleClick} size="m">
          {action.icon ?? <WppIconPlus slot="icon-start" />}
          {action.cta}
        </WppButton>
      )}
    </div>
  )
}
