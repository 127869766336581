import {
  WppButton,
  WppEmptyFolder,
  WppIconAdd,
  WppModal,
  WppSpinner,
  WppTypography,
} from '@wppopen/components-library-react'
import { AgGridReact } from 'ag-grid-react'
import clsx from 'clsx'
import { useRef, useState, useEffect, useCallback } from 'react'

import { useGetAgency } from 'api/queries/agencies/useGetAgency'
import HeaderCell from 'components/Grid/HeaderCell'
import { FileObject, useFileViewOrDownload } from 'hooks/useFileViewOrDownload'

import { AgencyAddFileModal } from './AgencyAddFileModal'
import { AgencyDeleteFileModal } from './AgencyDeleteFileModal'
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-alpine.css'
import '@wppopen/components-library/dist/collection/ag-theme-wpp.css'
import { columnDefenitions } from './columnsDefenitions'
import { AgencyFile } from '@/types/agencies/agencies'

interface AgencyFileListProps {
  agencyId: string
  actionsAllowed?: boolean
  disabled?: boolean
  onKnowledgeBaseUpdate?: () => void
}

export type TableDataItem = AgencyFile
const AgencyFileList = ({ agencyId, onKnowledgeBaseUpdate, disabled }: AgencyFileListProps) => {
  const [isFetchingFile, viewOrDownloadFile] = useFileViewOrDownload()
  const { data: agency, isLoading } = useGetAgency({ params: { id: agencyId } })
  const handleDownloadFileClick = useCallback(
    (fileObject: FileObject, downLoadFile?: boolean) => {
      viewOrDownloadFile({ fileObjects: [fileObject], downloadFile: downLoadFile })
    },
    [viewOrDownloadFile],
  )
  const handleDeleteFileClick = (id: string) => {
    setfileId(id)
  }
  const gridRef = useRef<AgGridReact<TableDataItem>>(null)
  const columnDefs = columnDefenitions(true, handleDownloadFileClick, handleDeleteFileClick)
  const [fileId, setfileId] = useState<string | null>(null)
  const [fileList, setFileList] = useState<AgencyFile[]>([])
  const [isFileModalOpen, setIsFileModalOpen] = useState(false)

  useEffect(() => {
    if (agency.files) {
      setFileList(agency.files)
    }
  }, [agency.files])

  return (
    <div className={clsx('flex flex-col gap-5')}>
      <div className="flex justify-between items-start">
        <div>
          <WppTypography type="xl-heading">Files</WppTypography>
          <br />
          <WppTypography type="s-body">Knowledge base will be updated with imported files. </WppTypography>
        </div>
        <div>
          <WppButton size="s" variant="secondary" onClick={() => setIsFileModalOpen(true)} disabled={disabled}>
            <WppIconAdd slot="icon-start" />
            Add file
          </WppButton>
        </div>
      </div>
      {fileList.length > 0 && (
        <div data-testid="ag-grid-table ">
          <div className="ag-theme-wpp mb-[32] w-full" key="123">
            <AgGridReact
              rowStyle={isFetchingFile ? { opacity: 0.5, pointerEvents: 'none' } : {}}
              ref={gridRef}
              gridId="agency-files-grid"
              rowData={fileList}
              columnDefs={columnDefs}
              loading={isLoading}
              defaultColDef={{
                headerComponent: HeaderCell,
                sortable: false,
              }}
              onRowClicked={({ data, event }) => {
                const target = event?.target as HTMLElement
                if (!target?.dataset?.contextMenu) {
                  viewOrDownloadFile({ fileObjects: [{ key: data.fileKey, name: data.fileName }] })
                }
              }}
              rowDragMultiRow
              animateRows
              rowDragManaged
              loadingOverlayComponent={() => <WppSpinner size="m" />}
              rowClass="cursor-pointer"
              rowClassRules={{
                'with-error': data => (data.data ? !!data.data.meta?.error : false),
              }}
              domLayout="autoHeight"
            />
          </div>
        </div>
      )}
      {fileList.length === 0 && (
        <div className="flex flex-col gap-6 items-center justify-center min-h-96">
          <WppEmptyFolder width={120} height={120} />
          <div className="flex flex-col gap-2 items-center">
            <WppTypography type="m-strong">No files yet</WppTypography>
            <WppTypography type="s-body">Add a file the knowledge base</WppTypography>
          </div>
          <WppButton variant="primary" onClick={() => setIsFileModalOpen(true)} disabled={disabled}>
            <WppIconAdd slot="icon-start" />
            Add file
          </WppButton>
        </div>
      )}

      <WppModal open={fileId !== null} onWppModalCloseComplete={() => setfileId(null)} size="s">
        <AgencyDeleteFileModal
          fileId={fileId ?? ''}
          handleModalClose={id => {
            if (id) {
              setFileList(fileList.filter(file => file.id !== id))
            }
            setfileId(null)
          }}
        />
      </WppModal>

      <AgencyAddFileModal
        agency={agency}
        isOpen={isFileModalOpen}
        onClose={() => {
          setIsFileModalOpen(false)
        }}
        onSave={() => {
          setIsFileModalOpen(false)
          onKnowledgeBaseUpdate?.()
        }}
      />
    </div>
  )
}

export default AgencyFileList
