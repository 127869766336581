// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.HruRc p,.HruRc ul,.HruRc ol{margin-bottom:22px;margin-bottom:var(--wpp-typography-s-body-line-height, 22px)}.HruRc ul li,.HruRc ol li{margin-bottom:0;margin-bottom:initial;display:list-item}.HruRc ul,.HruRc ol{display:block;margin-block-start:0;margin-block-end:0;margin-inline-start:0px;margin-inline-end:0px;padding-inline-start:25px;unicode-bidi:isolate}.HruRc ul{list-style-type:disc}.HruRc ol{list-style-type:decimal}`, "",{"version":3,"sources":["webpack://./src/pages/settings/useCases/useCasesDetails.module.scss"],"names":[],"mappings":"AAAA,6BAGE,kBAAA,CAAA,4DAAA,CAGF,0BAEE,eAAA,CAAA,qBAAA,CACA,iBAAA,CAGF,oBAEE,aAAA,CACA,oBAAA,CACA,kBAAA,CACA,uBAAA,CACA,qBAAA,CACA,yBAAA,CACA,oBAAA,CAGF,UACE,oBAAA,CAGF,UACE,uBAAA","sourcesContent":[".markDownWrapper p,\n.markDownWrapper ul,\n.markDownWrapper ol {\n  margin-bottom: var(--wpp-typography-s-body-line-height, 22px);\n}\n\n.markDownWrapper ul li,\n.markDownWrapper ol li {\n  margin-bottom: unset;\n  display: list-item;\n}\n\n.markDownWrapper ul,\n.markDownWrapper ol {\n  display: block;\n  margin-block-start: 0;\n  margin-block-end: 0;\n  margin-inline-start: 0px;\n  margin-inline-end: 0px;\n  padding-inline-start: 25px;\n  unicode-bidi: isolate;\n}\n\n.markDownWrapper ul {\n  list-style-type: disc;\n}\n\n.markDownWrapper ol {\n  list-style-type: decimal;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"markDownWrapper": `HruRc`
};
export default ___CSS_LOADER_EXPORT___;
