import {
  WppButton,
  WppDivider,
  WppInput,
  WppTypography,
  WppSelect,
  WppListItem,
  WppCheckbox,
  WppTextareaInput,
} from '@wppopen/components-library-react'
import clsx from 'clsx'
import { useCallback, useEffect, useMemo, useState } from 'react'

import { AddUseCaseParams } from 'api/fetchers/use-cases/createUseCase'
import { useCreateUseCase } from 'api/mutations/use-cases/useCreateUseCase'
import { useUpdateUseCase } from 'api/mutations/use-cases/useUpdateUseCase'
import { useGetAllAgencies } from 'api/queries/agencies/useGetAgencies'
import { useFileUploadUrls } from 'api/queries/files/useFileUploadUrls'
import { useMarkets } from 'api/queries/markets/useMarkets'
import { useRegions } from 'api/queries/markets/useRegions'
import { usePitchTypes } from 'api/queries/pitch-types/usePitchTypes'
import { queryClient } from 'app/Root'
import { LoaderProgressWithDescription } from 'components/LoaderProgressWithDescription'
import { taskQueuer, TaskStatusResponse } from 'components/TasksNotifications/TaskQueuer'
import { FileUploadChangeProps } from 'components/wpp-controlled-upload/WppControlledUpload'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { MarketType } from 'types/markets/market'
import { UseCase } from 'types/use-cases/useCase'

import { ClientAutocomplete } from './clientSelect/ClientAutocomplete'
import { UseCaseEditAddFile } from './UseCaseEditAddFile'
import style from './UseCasesEditAdd.module.scss'

export interface Props {
  useCase?: UseCase
  handleCancel?: () => void
  handleSave?: (name?: string, description?: string) => void
  handleEditing?: (isEditing: boolean) => void
}

export const UseCasesEditAdd = ({ useCase, handleCancel, handleSave, handleEditing }: Props) => {
  const [{ data: fileUploadUrls }, generateFileUploadUrls] = useFileUploadUrls()

  const { data: marketsData = [] } = useMarkets()
  const { data: agencies = [] } = useGetAllAgencies()
  const { data: regions = [] } = useRegions()
  const { data: pitchTypes = [] } = usePitchTypes()
  const markets = useMemo(() => [...regions, ...marketsData] as MarketType[], [marketsData, regions])
  const subCategories = useMemo(
    () => [
      'Context',
      'Client RFP and Briefs',
      'Pitch and RFP Responses',
      'Commercials',
      'Case Studies',
      'Competitor Intel',
    ],
    [],
  )
  const [tasksPending, setTasksPending] = useState<string[]>([])
  const [fileUploadHasError, setFileUploadHasError] = useState(false)
  const [nameValue, setNameValue] = useState<string>(useCase?.title || '')
  const [descriptionValue, setDescriptionValue] = useState<string>(useCase?.description || '')
  const [clientNamesSelectValue, setClientNamesSelectValue] = useState(useCase?.clientId || '')
  const [agencieSelectValues, setAgencieSelectValues] = useState<string[]>(useCase?.agencyIds || [])
  const [pitchTypeSelectValues, setPitchTypeSelectValues] = useState<string[]>(useCase?.pitchTypes || [])
  const [marketsSelectValues, setMarketsSelectValues] = useState<string[]>(useCase?.markets || [])
  const [subCategoriesSelectValues, setSubCategoriesSelectValues] = useState(useCase?.subCategory || '')
  const [existingFileName, setExistingFileName] = useState(useCase?.fileName || null)
  const [isConfidentialValue, setIsConfidentialValue] = useState(useCase?.isConfidential ?? true)
  const [forceClientReset, setForceClientReset] = useState<string[] | undefined>(undefined)

  const [wppFiles, setWppFiles] = useState<File[] | null>(null)
  const existingFileUrl = useCase?.fileUrl || null

  const [disableSave, setDisableSave] = useState(!useCase?.title)
  const [taskStatus, setTaskStatus] = useState<TaskStatusResponse | null>(null)
  const [loading, setLoading] = useState(false)
  const editInProgress = (tasksPending.length > 0 || loading) && useCase !== undefined

  useEffect(() => {
    handleEditing && handleEditing(editInProgress)
  }, [editInProgress, handleEditing])

  const taskStatusCallback = useCallback(
    (resp: TaskStatusResponse) => {
      setTaskStatus(resp)
      const { status, completed, currentStep, error, handleLocally } = resp || {}
      if (!handleLocally) {
        return
      }
      if (status === 'Parsing files for processing...' && currentStep === 1) {
        taskQueuer.showToast({
          message: 'Parsing files for processing...',
          type: 'information',
          duration: 14000,
        })
      }
      if (status === 'Processing...' && currentStep === 1) {
        taskQueuer.showToast({
          message: 'Processing...',
          type: 'information',
          duration: 14000,
        })
      }
      if (status === 'failed') {
        taskQueuer.showToast({
          message: `Use case ${useCase ? 'edit' : 'creation'} was not successful`,
          type: 'error',
        })
      }

      if (status?.toLowerCase()?.includes('error') || error) {
        const isFileError = status?.toLowerCase()?.includes('file')

        if (isFileError && useCase !== undefined) {
          taskQueuer.showToast({
            message:
              'The file upload was unsuccessful. The previous file is still in use. Please try uploading the new file again.',
            type: 'error',
            duration: 0,
          })
        } else {
          taskQueuer.showToast({
            message: `Use case ${useCase ? 'edit' : 'creation'} was not successful. ${isFileError ? 'Error processing file.  Please try again.' : ''}`,
            type: 'error',
            duration: 10000,
          })
        }

        handleSave?.(nameValue, descriptionValue)
        handleClearAll()

        return
      }

      if (status === 'completed' || completed) {
        handleClearAll()
        queryClient.invalidateQueries({ queryKey: [ApiQueryKeys.CLIENTS] })
        queryClient.invalidateQueries({ queryKey: [ApiQueryKeys.USE_CASE] })
        queryClient.invalidateQueries({ queryKey: [ApiQueryKeys.USE_CASES] }).then(() => {
          taskQueuer.showToast({
            message: `${!useCase ? "You've successfully added a new use case" : "You've successfully updated " + useCase?.title + ' use case'}`,
            type: 'success',
          })
        })

        handleSave?.(nameValue, descriptionValue)
      }
    },
    [useCase, handleSave, nameValue, descriptionValue],
  )

  const isEditMode = typeof useCase !== 'undefined'

  const maxLength = 1000

  const { mutateAsync: updateUseCase } = useUpdateUseCase()
  const { mutateAsync: addUseCase } = useCreateUseCase({
    onError: error => {
      taskQueuer.showToast({
        message: error.message,
        type: 'error',
      })
    },
  })

  const prepareRequest = useCallback(
    (fileKey: string): AddUseCaseParams => {
      let request: AddUseCaseParams
      request = {
        params: {
          file_key: fileKey,
          id: useCase?.id,
          title: nameValue,
          markets: marketsSelectValues,
          client: clientNamesSelectValue,
          agencyIds: agencieSelectValues,
          pitch_types: pitchTypeSelectValues,
          sub_category: subCategoriesSelectValues,
          isConfidential: isConfidentialValue,
          description: descriptionValue,
        },
      }

      if (useCase?.id) {
        request = {
          params: {
            ...request.params,
            id: useCase?.id,
          },
        }
      }

      /*  */
      return request
    },
    [
      useCase,
      nameValue,
      marketsSelectValues,
      clientNamesSelectValue,
      agencieSelectValues,
      pitchTypeSelectValues,
      subCategoriesSelectValues,
      isConfidentialValue,
      descriptionValue,
    ],
  )

  const onFileUploadChange = useCallback(
    ({ files, hasError }: FileUploadChangeProps) => {
      setWppFiles(files as File[])
      setFileUploadHasError(hasError)
      if (files.length > 0) {
        setExistingFileName(files[0].name)
      } else {
        setExistingFileName(useCase?.fileName || null)
      }
    },
    [useCase],
  )

  const handleClearAll = () => {
    setLoading(false)
    setTasksPending([])
    setTaskStatus(null)
    setFileUploadHasError(false)
    setNameValue('')
    setDescriptionValue('')
    setClientNamesSelectValue('')
    setAgencieSelectValues([])
    setPitchTypeSelectValues([])
    setMarketsSelectValues([])
    setSubCategoriesSelectValues('')
    setIsConfidentialValue(true)
    setWppFiles([])
    setForceClientReset([])
    setExistingFileName(null)
  }

  const disableSaveMemo = useMemo(() => {
    if (
      nameValue &&
      clientNamesSelectValue &&
      agencieSelectValues.length &&
      pitchTypeSelectValues.length &&
      marketsSelectValues.length &&
      !disableSave &&
      !useCase &&
      wppFiles?.length &&
      wppFiles?.length > 0
    ) {
      return false
    } else if (
      nameValue &&
      clientNamesSelectValue &&
      agencieSelectValues.length &&
      pitchTypeSelectValues.length &&
      marketsSelectValues.length &&
      !disableSave &&
      useCase
    ) {
      return false
    } else {
      return true
    }
  }, [
    nameValue,
    clientNamesSelectValue,
    agencieSelectValues.length,
    pitchTypeSelectValues.length,
    marketsSelectValues.length,
    disableSave,
    useCase,
    wppFiles?.length,
  ])
  /*
   * For updating in the background we doing it on blur
   */
  const onHandleEdit = async () => {
    if (isEditMode && typeof useCase?.id !== 'undefined') {
      if (!nameValue) {
        return taskQueuer.showToast({
          message: 'Please enter a name for the use case',
          type: 'error',
        })
      }

      setLoading(true)
      if (wppFiles?.length) {
        generateFileUploadUrls({ file_names: wppFiles.map(file => file.name), file_type: 'usecase' })
      } else {
        try {
          const res = await updateUseCase({
            id: useCase.id,
            title: nameValue,
            markets: marketsSelectValues,
            client: clientNamesSelectValue,
            agencyIds: agencieSelectValues,
            pitch_types: pitchTypeSelectValues,
            sub_category: subCategoriesSelectValues,
            isFavorite: useCase.isFavorite,
            isConfidential: isConfidentialValue,
            description: descriptionValue,
          })
          const id = taskQueuer.queueTask({
            id: res.data.id,
            entityType: 'useCase',
            handleLocally: true,
            callback: taskStatusCallback,
          })
          if (id) {
            setTasksPending([...tasksPending, id])
          }
        } catch (e) {
          taskQueuer.showToast({
            message: 'Something went wrong while editing the use case. Please try again.',
            type: 'error',
          })
          setLoading(false)
        }
      }
    }
  }

  const onHandleSave = useCallback(async () => {
    setDisableSave(true)
    setLoading(true)

    if (wppFiles && !useCase) {
      generateFileUploadUrls({ file_names: wppFiles.map(file => file.name), file_type: 'usecase' })
    }

    if (!nameValue) {
      return taskQueuer.showToast({
        message: 'Please enter a name for the use case',
        type: 'error',
      })
    }
  }, [generateFileUploadUrls, nameValue, useCase, wppFiles])

  const onHandleCancel = () => {
    handleClearAll()
    handleCancel && handleCancel()
  }

  useEffect(() => {
    setDisableSave(!nameValue)
  }, [nameValue])

  useEffect(() => {
    if (fileUploadUrls && fileUploadUrls.length) {
      uploadFiles()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileUploadUrls])

  const uploadFiles = async () => {
    if (fileUploadUrls && fileUploadUrls.length && wppFiles) {
      fileUploadUrls.forEach(async fileUrl => {
        try {
          const formDataAPI = new FormData()
          const files = wppFiles.map(file => new Blob([file], { type: 'application/pdf' }))
          const file = files[0]
          formDataAPI.append('file', file, fileUrl.name)

          const fileResponse = await fetch(fileUrl.signed_url, {
            headers: {
              'Content-Type': 'application/pdf',
              'x-goog-content-length-range': '1,1000000000',
            },
            body: formDataAPI,
            method: 'put',
          })
          if (!fileResponse.ok) {
            throw new Error('File upload failed')
          }
        } catch (e) {
          taskQueuer.showToast({
            message: 'Your file upload was not successful',
            type: 'error',
          })
          setDisableSave(false)
          setLoading(false)
          setWppFiles([])
          if (useCase?.id) {
            setExistingFileName(useCase?.fileName || null)
          }
          setFileUploadHasError(false)
          return
        }

        let request = prepareRequest(fileUrl.key)

        if (useCase?.id) {
          try {
            const res = await updateUseCase({
              fileKey: fileUrl.key,
              id: useCase.id,
              title: nameValue,
              markets: marketsSelectValues,
              client: clientNamesSelectValue,
              agencyIds: agencieSelectValues,
              pitch_types: pitchTypeSelectValues,
              sub_category: subCategoriesSelectValues,
              isFavorite: useCase.isFavorite,
              isConfidential: isConfidentialValue,
              description: descriptionValue,
            })
            // Add task to queue and handle callback locally.
            const id = taskQueuer.queueTask({
              id: res.data.id,
              handleLocally: true,
              callback: taskStatusCallback,
              entityType: 'useCase',
            })
            if (id) {
              setTasksPending([...tasksPending, id])
            }
          } catch (e) {
            taskQueuer.showToast({
              message: 'Use case update was not successful',
              type: 'error',
            })
          }
        } else {
          try {
            const res = await addUseCase(request)
            // Add task to queue and handle callback globally
            taskQueuer.queueTask({
              id: res.data.id,
              entityType: 'useCase',
              queryKeysToInvalidate: [ApiQueryKeys.USE_CASES, ApiQueryKeys.CLIENTS, ApiQueryKeys.USE_CASE],
              successMessage: `${nameValue} use case successfully added`,
              errorMessage: `Something went wrong while adding ${nameValue} use case. Please try again.`,
            })
            taskQueuer.showToast({
              message: 'Processing...',
              type: 'information',
              duration: 5000,
            })
            handleSave?.(nameValue, descriptionValue)
            queryClient.resetQueries({ queryKey: [ApiQueryKeys.FILE_UPLOAD_URLS] })
            handleClearAll()
          } catch (e) {
            taskQueuer.showToast({
              message: 'Use case creation was not successful',
              type: 'error',
            })
          }
        }
      })
    }
  }

  return (
    <>
      <div>
        <WppInput
          name={nameValue}
          placeholder="Type use case name"
          required
          disabled={editInProgress}
          value={nameValue}
          onWppChange={e => setNameValue(e?.detail?.value || '')}
          {...(!isEditMode ? { labelConfig: { text: 'Use Case Name' } } : {})}
        />
      </div>
      {/* DESCRIPTION */}
      <div className="mb-4">
        <WppTextareaInput
          labelConfig={{
            text: 'Description',
          }}
          disabled={editInProgress}
          onWppChange={e => setDescriptionValue(e?.detail?.value || '')}
          value={descriptionValue}
          charactersLimit={maxLength}
          maxMessageLength={maxLength}
          warningThreshold={maxLength - 100}
          content={descriptionValue}
          className={style.textArea}
        />
      </div>

      {/* AGENCIES */}
      <WppSelect
        disabled={editInProgress}
        aria-label="Agencies"
        title="Agencies"
        placeholder="Select agencies"
        labelConfig={{
          text: 'Agencies',
        }}
        type="multiple"
        withSearch
        withFolder
        value={agencieSelectValues}
        required
        onWppChange={e => {
          setAgencieSelectValues(e.detail.value)
        }}
      >
        {(agencies as { name: string; id: string }[])?.map(item => (
          <WppListItem key={item.id} value={item.id}>
            <p slot="label">{item.name}</p>
          </WppListItem>
        ))}
      </WppSelect>

      {/* CLIENT */}
      <ClientAutocomplete
        disabled={editInProgress}
        defaultClient={useCase?.id ? { id: String(useCase?.clientId), label: String(useCase?.client) } : null}
        onChange={setClientNamesSelectValue}
        forceReset={forceClientReset}
      />

      {/* MARKETS */}
      <WppSelect
        disabled={editInProgress}
        aria-label="Markets"
        title="Markets"
        placeholder="Select markets"
        type="multiple"
        labelConfig={{
          text: 'Markets',
        }}
        withSearch
        withFolder
        value={[...marketsSelectValues]}
        required
        onWppChange={e => {
          setMarketsSelectValues(e.detail.value)
        }}
      >
        {markets?.map(item => (
          <WppListItem key={item.id} value={item.name}>
            <p slot="label">{item.name}</p>
          </WppListItem>
        ))}
      </WppSelect>
      {/* PITCH TYPES */}
      <WppSelect
        disabled={editInProgress}
        aria-label="Pitch types"
        title="Pitch Types"
        placeholder="Select pitch type"
        type="multiple"
        labelConfig={{
          text: 'Pitch Types',
        }}
        withSearch
        value={pitchTypeSelectValues}
        withFolder
        required
        onWppChange={e => {
          setPitchTypeSelectValues(e.detail.value)
        }}
      >
        {pitchTypes?.map(item => (
          <WppListItem key={item.id} value={item.typeDescription}>
            <p slot="label">{item.typeDescription}</p>
          </WppListItem>
        ))}
      </WppSelect>

      {/* SUB CATEGORIES */}
      <WppSelect
        disabled={editInProgress}
        aria-label="Subcategory"
        title="Subcategory"
        placeholder="Select Subcategory"
        labelConfig={{
          text: 'Subcategory',
        }}
        type="single"
        withSearch
        withFolder
        value={subCategoriesSelectValues}
        required
        onWppChange={e => {
          setSubCategoriesSelectValues(e.detail.value)
        }}
      >
        {subCategories?.map(item => (
          <WppListItem key={item} value={item}>
            <p slot="label">{item}</p>
          </WppListItem>
        ))}
      </WppSelect>

      <WppCheckbox
        disabled={editInProgress}
        required
        checked={isConfidentialValue}
        labelConfig={{ text: 'This file is confidential and must not be shared with others' }}
        className={clsx(useCase && 'mt-2')}
        onWppChange={e => setIsConfidentialValue(e?.detail?.checked)}
      />

      <div className="py-4">
        <div className="mb-2 flex flex-row items-center gap-1">
          <WppTypography type="s-strong" className={style.fileUploadtype}>
            File Upload
          </WppTypography>
        </div>
        {/* File upload or edit */}
        {editInProgress ? (
          <div className="flex items-center justify-center">
            <LoaderProgressWithDescription taskStatus={taskStatus} />
          </div>
        ) : (
          <UseCaseEditAddFile
            disabled={editInProgress}
            wppFile={wppFiles || []}
            existingFileUrl={existingFileUrl}
            isEditMode={isEditMode}
            onFileUploadChange={onFileUploadChange}
            toast={taskQueuer}
            existingFileName={existingFileName}
          />
        )}
      </div>

      <>
        {!useCase && <WppDivider />}
        <div className="flex pb-10">
          <WppButton onClick={onHandleCancel} className="ml-auto mr-4" variant="secondary" disabled={editInProgress}>
            Cancel
          </WppButton>

          <WppButton
            onClick={!useCase ? onHandleSave : onHandleEdit}
            className="mr-4"
            disabled={disableSaveMemo || descriptionValue.length > maxLength || editInProgress || fileUploadHasError}
          >
            Save
          </WppButton>
        </div>
      </>
    </>
  )
}
