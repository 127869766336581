import { FileItemType } from '@wppopen/components-library/dist/types/components'
import {
  WppTextareaInput,
  WppButton,
  WppDivider,
  WppInput,
  WppTypography,
  WppTooltip,
  WppIconInfo,
} from '@wppopen/components-library-react'
import { AxiosError, AxiosResponse } from 'axios'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { useAddAgency } from 'api/mutations/agencies/useAddAgency'
import { useAddAgencyFile } from 'api/mutations/agencies/useAddAgencyFile'
import { useFileUploadUrls } from 'api/queries/files/useFileUploadUrls'
import { queryClient } from 'app/Root'
import { navigation } from 'components/SidebarCmp'
import { taskQueuer } from 'components/TasksNotifications/TaskQueuer'
import { WppControlledUpload, FileUploadChangeProps } from 'components/wpp-controlled-upload/WppControlledUpload'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { AgencyAddedResponse, AgencyFileAddedResponse } from 'types/agencies/agencies'

import style from './addagency.module.scss'

export interface AddAgencyModalProps {
  handleCancel?: () => void
  handleSave?: (data: {
    id: string
    name: string
    description: string
    fileName?: string | null
    fileStatusTaskId?: string | null
  }) => void
}

export const AddAgencyModal = ({ handleCancel, handleSave }: AddAgencyModalProps) => {
  const navigate = useNavigate()
  const [descriptionValue, setDescriptionValue] = useState<string>('')
  const [agencyExists, setAgencyExists] = useState(false)
  const [agencyId, setAgencyId] = useState<string | null>(null)
  const [nameValue, setNameValue] = useState<string>('')
  const [wppFiles, setWppFiles] = useState<FileItemType[]>([])
  const [fileHasError, setFileHasError] = useState(false)
  const textAreaRef = useRef<HTMLWppTextareaInputElement>(null)
  const [isUploading, setIsUploading] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const maxLength = 2000
  const canSave = !!nameValue && descriptionValue.length < maxLength && !fileHasError

  const [{ data: fileUploadUrls }, generateFileUploadUrls] = useFileUploadUrls()

  const { mutateAsync: addAgency } = useAddAgency({
    onError: error => {
      taskQueuer.showToast({
        message: error.message,
        type: 'error',
      })
    },
  })

  const { mutateAsync: addAgencyFile } = useAddAgencyFile({
    onError: error => {
      taskQueuer.showToast({
        message: error.message,
        type: 'error',
      })
    },
  })

  const onFileUploadChange = useCallback(({ files, hasError }: FileUploadChangeProps) => {
    setWppFiles(files)
    setFileHasError(hasError)
  }, [])

  const handleClearAll = () => {
    setAgencyId(null)
    setNameValue('')
    setDescriptionValue('')
    setWppFiles([])
    setFileHasError(false)
    setAgencyExists(false)
    queryClient.removeQueries({ queryKey: [ApiQueryKeys.FILE_UPLOAD_URLS] })
  }

  const onHandleCancel = () => {
    handleClearAll()
    handleCancel && handleCancel()
  }

  const onHandleSave = useCallback(async () => {
    if (!nameValue) {
      return taskQueuer.showToast({
        message: 'Please enter a name for the agency',
        type: 'error',
      })
    }
    setIsSaving(true)
    const request = { params: { name: nameValue, description: descriptionValue } }

    let agencyResponse: AxiosResponse<AgencyAddedResponse> | null = null
    let agencyId: string | null = null
    try {
      agencyResponse = await addAgency(request)
      if (!agencyResponse) {
        throw new Error('Agency creation failed')
      }
      agencyId = agencyResponse?.data?.id ?? null
      setAgencyId(agencyId)
      queryClient.invalidateQueries({ queryKey: [ApiQueryKeys.AGENCIES] })
    } catch (e) {
      const error = e as AxiosError
      if (error.status === 409) {
        taskQueuer.showToast({
          message: 'Agency name already exists. Please use a different one.',
          type: 'error',
        })
        setAgencyExists(true)
        setIsSaving(false)

        return
      }
      taskQueuer.showToast({
        message: 'Agency creation was not successful',
        type: 'error',
      })
      setIsSaving(false)
    }

    if (wppFiles.length > 0) {
      setIsUploading(true)
      generateFileUploadUrls({ file_names: wppFiles.map(file => file.name), file_type: 'agency_file' })
    } else {
      await handleSaveAgency(agencyId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [generateFileUploadUrls, nameValue, descriptionValue, wppFiles])

  useEffect(() => {
    if (fileUploadUrls && fileUploadUrls.length) {
      uploadFiles()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileUploadUrls])

  const uploadFiles = async () => {
    if (fileUploadUrls && fileUploadUrls.length && wppFiles.length) {
      setIsUploading(true)
      for (const fileUrl of fileUploadUrls) {
        try {
          const formDataAPI = new FormData()
          const files = wppFiles.map(file => new Blob([file as File], { type: 'application/pdf' }))
          const file = files[0]
          formDataAPI.append('file', file, fileUrl.name)
          const fileResponse = await fetch(fileUrl.signed_url, {
            headers: {
              'Content-Type': 'application/pdf',
              'x-goog-content-length-range': '1,1000000000',
            },
            body: formDataAPI,
            method: 'put',
          })
          if (!fileResponse.ok) {
            throw new Error('File upload failed')
          }
        } catch (e) {
          console.error('Error uploading file', e)
          taskQueuer.showToast({
            message: 'Agency successfully created',
            type: 'success',
          })

          taskQueuer.showToast({
            message: 'File upload failed',
            type: 'error',
          })
          setIsUploading(false)
          setWppFiles([])
          setFileHasError(false)
          setIsSaving(false)
          navigate(`${navigation.agencyDashboard}/${agencyId}`)
          return
        }
      }

      handleSaveAgency(agencyId)
    }
  }

  const handleSaveAgency = async (agencyId: string | null) => {
    if (!agencyId) {
      taskQueuer.showToast({
        message: 'Agency creation was not successful',
        type: 'error',
      })
      setIsUploading(false)
      return
    }

    try {
      let fileUploadRes: AxiosResponse<AgencyFileAddedResponse> | null = null
      if (fileUploadUrls?.[0]) {
        fileUploadRes = await addAgencyFile({
          params: {
            file_key: fileUploadUrls[0].key,
          },
          agencyId: agencyId,
        })
      }

      taskQueuer.showToast({
        message: "You've successfully added new agency.",
        type: 'success',
      })

      handleSave?.({
        id: agencyId,
        name: nameValue,
        description: descriptionValue,
        fileName: wppFiles[0]?.name,
        fileStatusTaskId: fileUploadRes?.data?.id,
      })
      setIsSaving(false)
      handleClearAll()

      queryClient.removeQueries({ queryKey: [ApiQueryKeys.FILE_UPLOAD_URLS] })
    } catch (e) {
      console.error('Error uploading file', e)
      taskQueuer.showToast({
        message: 'Agency successfully created',
        type: 'success',
      })

      taskQueuer.showToast({
        message: 'File upload failed',
        type: 'error',
      })
      queryClient.removeQueries({ queryKey: [ApiQueryKeys.FILE_UPLOAD_URLS] })
      setIsUploading(false)
      setWppFiles([])
      setFileHasError(false)
      setIsSaving(false)
      navigate(`${navigation.agencyDashboard}/${agencyId}`)
    }
  }

  return (
    <div className="-mx-8">
      <WppDivider />

      <div
        className="px-8 flex flex-col gap-6 py-6 overflow-y-auto"
        style={{
          minHeight: 'calc(100vh - 158px)',
          height: 'calc(100vh - 158px)',
        }}
      >
        <div>
          <WppInput
            labelConfig={{
              text: 'Agency Name',
            }}
            name={nameValue}
            placeholder="Add an agency name"
            required
            value={nameValue}
            onWppChange={e => {
              setNameValue(e?.detail?.value || '')
              setAgencyExists(false)
            }}
            messageType={agencyExists ? 'error' : undefined}
            message={agencyExists ? 'Name already exists. Use a different one.' : undefined}
          />
        </div>
        <WppTextareaInput
          ref={textAreaRef}
          labelConfig={{
            text: 'Description',
          }}
          name="description"
          placeholder="Describe agency"
          onWppChange={e => setDescriptionValue(e?.detail?.value || '')}
          value={descriptionValue}
          charactersLimit={maxLength}
          maxMessageLength={maxLength}
          warningThreshold={maxLength - 100}
          content={descriptionValue}
          className={style.textArea}
        />

        <WppDivider />

        <div className="mb-2 flex flex-row items-center gap-1">
          <WppTypography type="s-strong" className={style.fileUploadtype}>
            Knowledge Base Upload
          </WppTypography>
          <WppTypography type="s-body">(Optional)</WppTypography>
          <WppTooltip
            text="Knowledge base will be changed according to the import selection."
            className={style.filesInfoTooltip}
          >
            <WppIconInfo />
          </WppTooltip>
        </div>
        <div className="mb-2 flex flex-row">
          <WppTypography type="s-body">
            Upload a new file to fill out the agency knowledge base content. After uploading the file, you can preview
            what is being imported and make a selection to confirm the import.
          </WppTypography>
        </div>
        <WppControlledUpload
          acceptConfig={{ 'application/pdf': ['.pdf'] }}
          onFileUploadChange={onFileUploadChange}
          format="arrayBuffer"
          value={wppFiles}
        />
      </div>

      <WppDivider />

      <div className="flex justify-end gap-4 px-8 pt-4 pb-6">
        <WppButton onClick={onHandleCancel} variant="secondary" disabled={isSaving}>
          Cancel
        </WppButton>
        <WppButton
          onClick={() => {
            onHandleSave()
          }}
          disabled={!canSave || isSaving || isUploading}
        >
          {isUploading ? 'Uploading...' : isSaving ? 'Saving...' : 'Next'}
        </WppButton>
      </div>
    </div>
  )
}
