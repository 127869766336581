import { WppEmptyError, WppTypography } from '@wppopen/components-library-react'

export default function UseCaseSummaryError() {
  return (
    <div className="flex flex-col gap-6 items-center justify-center w-full px-6 py-16">
      <WppEmptyError width={120} height={120} />
      <div className="flex flex-col gap-2 items-center justify-center">
        <WppTypography type="m-strong">Error</WppTypography>
        <WppTypography type="s-body">
          Something is wrong with the document format. Not able to generate summary. Try again or contact support.
        </WppTypography>
      </div>
    </div>
  )
}
