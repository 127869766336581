import { WppSkeleton, WppTypography } from '@wppopen/components-library-react'

export const ProjectDetail = ({ fetching = true, name, value }: { fetching: boolean; name: string; value: string }) => {
  return (
    <p className="flex flex-nowrap w-full" data-testid="projectDetail-container">
      <WppTypography tag="span" type="s-strong" className="text-grey mr-2 no-word-break">
        {name}: {'  '}
      </WppTypography>
      {fetching ? (
        <WppSkeleton height={20} width="100%" />
      ) : (
        <WppTypography type="s-body" tag="span">
          {' '}
          {value}
        </WppTypography>
      )}
    </p>
  )
}
